<template>
  <div class="homebackmc">
    <div class="exec-pagent3">
      <div class="exec-dir2">
        <div class="content-title">
          <span>联系方式</span>
        </div>
        <div class="contact-content">
          <div>
            <div id="map-hq" class="map-hq"></div>
          </div>
          <div class="contact-content2">
            <span class="contact-title">{{ contactDataHq.title }}</span>

            <span class="contact-intro">电话：{{ contactDataHq.phone }}</span>
            <span class="contact-intro">地址：{{ contactDataHq.address }}</span>
            <span class="contact-intro">邮编：{{ contactDataHq.postal }}</span>

            <span class="contact-intro">微信：{{ contactDataHq.wchat }}</span>
            <span class="contact-intro">QQ：{{ contactDataHq.qq }}</span>
            <span class="contact-intro">邮箱：{{ contactDataHq.email }}</span>
          </div>
        </div>
        <div class="contact-content">
          <div>
            <div id="map-part" class="map-part"></div>
          </div>
          <div class="contact-content2">
            <span class="contact-title">{{ contactDataPart.title }}</span>

            <span class="contact-intro">电话：{{ contactDataPart.phone }}</span>
            <span class="contact-intro"
              >地址：{{ contactDataPart.address }}</span
            >
            <span class="contact-intro"
              >邮编：{{ contactDataPart.postal }}</span
            >

            <span class="contact-intro">微信：{{ contactDataPart.wchat }}</span>
            <span class="contact-intro">QQ：{{ contactDataPart.qq }}</span>
            <span class="contact-intro">邮箱：{{ contactDataPart.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "1b95597142aa0bbc654f2609eb9d975b",
};

export default {
  data() {
    return {
      contactDataHq: [],
      contactDataPart: [],
      Hqmap: null,
      Partmap: null,
    };
  },
  created() {
    this.initMapHq();
    this.initMapPart();
  },

  mounted() {
    this.getContactData();
    this.initMapHq();
    this.initMapPart();
  },
  activated() {
    this.getContactData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getContactData() {
      await this.$axios
        .get("/contacts/GetContactUser", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.contactDataHq = { ...response.data.data[0] };
          this.contactDataPart = { ...response.data.data[1] };
          // console.log(this.contactData);
        });
    },
    //总部地图
    initMapHq() {
      AMapLoader.load({
        key: "01ad6adcd3a5d81257c46b2304ce883b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.Hqmap = new AMap.Map("map-hq", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 15, // 初始化地图级别
            center: [123.424442, 41.766867], //中心点坐标
            resizeEnable: true,
          });
          // 循环所有的标记点
          var marker = new AMap.Marker({
            position: [123.424442, 41.766867], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            map: this.Hqmap,

            offset: new AMap.Pixel(-15, -20),
          });
          marker.title = `<span>沈阳办公地址（总部）</span>`;
          marker.content = JSON.stringify([
            "电话：" + this.contactDataHq.phone,
            "地址：" + this.contactDataHq.address,
          ]);
          // 将创建的点标记添加到已有的地图实例：
          marker.emit("click", { target: marker });
          this.Hqmap.add([marker]);
        })

        .catch((e) => {
          console.log(e);
        });
    },
    //分部地图
    initMapPart() {
      AMapLoader.load({
        key: "01ad6adcd3a5d81257c46b2304ce883b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.Partmap = new AMap.Map("map-part", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 15, // 初始化地图级别
            center: [123.428176, 41.765655], //中心点坐标
            resizeEnable: true,
          });
          var marker = new AMap.Marker({
            position: [123.428176, 41.765655], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            map: this.Partmap,
            offset: new AMap.Pixel(-15, -20),
          });
          marker.title = `<span>辽阳办公地址（分部）</span>`;
          marker.content = JSON.stringify([
            "电话：" + this.contactDataPart.phone,
            "地址：" + this.contactDataPart.address,
          ]);
          marker.emit("click", { target: marker });
          // 将创建的点标记添加到已有的地图实例：
          this.Partmap.add([marker]);
        })

        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 800px) {
  .homebackmc {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(173, 172, 172, 0.8)
      ),
      url("/assets/back.png") no-repeat;
    background-size: 100% 100%;
  }

  .exec-pagent3 {
    display: flex;
    // background-color: #f8f8f9;
    flex-direction: column;
    justify-content: center;
    margin-left: 6%;
    // width: 100%;
    margin-bottom: 5%;
    margin-top: 3%;
  }
  .contact-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 8%;
    font-size: 18px;
  }
  .exec-dir2 {
    height: 50%;
    width: 100%;
  }
  .content-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 32%;
  }
  .contact-title {
    width: 40%;
    text-align: justify;
    margin-top: 5%;
    font-size: 20px;
    border: 1px solid rgb(7, 72, 133);
    padding: 4px 4px 4px 4px;
    border-radius: 2px;
    // background: rgb(246, 242, 241);
    // text-align: center;
  }
  .contact-intro {
    width: 500px;
    text-align: justify;
    margin-top: 5%;
  }
  .contact-content2 {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .map-hq {
    overflow: hidden;
    width: 500px;
    height: 400px;
    margin: 0;
    border-radius: 2px;
  }
  .map-part {
    overflow: hidden;
    width: 500px;
    height: 400px;
    margin: 0;
    border-radius: 2px;
  }
}
@media screen and (max-width: 700px) {
  .homebackmc {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(205, 204, 204, 0.8)
      ),
      url("/assets/back.png") no-repeat;
    background-size: 100% 100%;
  }

  .exec-pagent3 {
    display: flex;
    // background-color: #f8f8f9;
    flex-direction: column;
    justify-content: center;
    // margin-left: 6%;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 3%;
  }
  .contact-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 8%;
  }
  .exec-dir2 {
    height: 50%;
    width: 100%;
  }
  .content-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 32%;
  }
  .contact-title {
    width: 50%;
    text-align: justify;
    margin-top: 5%;
    font-size: 20px;
    border: 1px solid rgb(7, 72, 133);
    padding: 4px 4px 4px 4px;
    border-radius: 2px;
    // background: rgb(246, 242, 241);
    // text-align: center;
  }
  .contact-intro {
    width: 480px;
    text-align: justify;
    margin-top: 5%;
  }
  .contact-content2 {
    margin-left: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .map-hq {
    overflow: hidden;
    width: 480px;
    height: 380px;
    margin: 0;
    border-radius: 2px;
  }
  .map-part {
    overflow: hidden;
    width: 480px;
    height: 380px;
    margin: 0;
    border-radius: 2px;
  }
}
</style>