<template>
  <div class="homebackfd">
    <div class="news-detail">
      <div class="detail-title">
        <span :title="foreAgentData.title">{{ foreAgentData.title }}</span>
      </div>
      <div class="detail-content">
        <div class="detail-intro" v-html="foreAgentData.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      foreAgentData: [],
    };
  },
  mounted() {
    this.getforeAgentData();
  },
  activated() {
    this.getforeAgentData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getforeAgentData() {
      await this.$axios
        .get("/agents/GetAgent", {
          params: {
            agentid: this.$route.params.str,
          },
        })
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.foreAgentData = { ...response.data };
        });
    },
  },
};
</script>

<style lang="less">
// pc端
@media screen and (min-width: 800px) {
  .homebackfd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .news-detail {
    height: 100%;
    width: 80%;
    margin-top: 3%;
  }
  .detail-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .detail-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 5%;
  }
}
// 移动端
@media screen and (max-width: 700px) {
  .homebackfd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .news-detail {
    // height: 100%;
    width: 80%;
    margin-top: 3%;
  }
  .detail-title {
    font-size: 25px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .detail-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 5%;
    // height: 500px;
  }
  .detail-intro {
    text-align: justify;
  }
}
</style>