<template>
  <div class="swiperBox">
    <div class="imgLeft" @click="clickLeft">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div id="swiper">
      <div class="imgBox">
        <div class="imgDiv" v-for="(item, index) of gloryData" :key="index">
          <img class="glory-pic" :src="item.picurl" />
          <div class="glory-text" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <div class="imgRight" @click="clickRight">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseSwiper",
  props: {
    speed: Number,
    direction: {
      default: "",
      type: String,
    },
    gloryData: {
      default: [],
      type: Array,
    },
  },
  data() {
    return {
      timer: null,
      theSpeed: this.speed,
      imgWidth: 260,
      theDirection: this.direction,
      // gloryList: this.gloryData,
      // gloryList: [],
    };
  },
  watch: {
    // watch: {
    //   data(newD, oldD) {
    //     this.initdata();
    //   },
    // },
  },
  // activated() {
  //   this.initdata();
  // },
  // created() {
  //   this.initdata();
  // },
  methods: {
    clickLeft() {
      this.theDirection = "left";
    },
    clickRight() {
      this.theDirection = "right";
    },
    // initdata() {
    //   this.gloryData.forEach((item) => {
    //     this.gloryList.push(item);
    //   });
    //   console.log(this.gloryData);
    // },
  },

  mounted() {
    // this.initdata();
    let imgBox = document.getElementsByClassName("imgBox")[0];
    //将imgBox下的图片进行拼接 循环展示图片
    imgBox.innerHTML += imgBox.innerHTML;
    let imgDiv = document.getElementsByClassName("imgDiv");
    imgBox.style.width = imgDiv.length * this.imgWidth + "px"; //设置div的宽度使图片可以放下
    let self = this;
    function autoScroll() {
      if (imgBox.offsetLeft < -(imgBox.offsetWidth / 2)) {
        //提前更新left值，实现循环展示
        imgBox.style.left = 0;
      }
      if (imgBox.offsetLeft > 0) {
        //向右滚动 提前更新left值，实现循环展示
        imgBox.style.left = -(imgBox.offsetWidth / 2) + "px";
      }
      if (self.theDirection == "left") {
        //向左滚动,值为负
        self.theSpeed = -Math.abs(self.theSpeed);
      } else {
        //向右滚动
        self.theSpeed = Math.abs(self.theSpeed);
      }
      // 求出总的left值，等于left值加上移动的速度（px值）
      imgBox.style.left = imgBox.offsetLeft + self.theSpeed + "px";
    }
    this.timer = setInterval(autoScroll, 30); //全局变量 ，保存返回的定时器
  },

  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style scoped>
.swiperBox {
  height: 80%;
  width: 100%;
  position: relative;
  /* margin-left: -5%; */
  margin-top: 3%;
}
.imgLeft {
  left: 0;
  top: 40%;
  width: 27px;
  height: 38px;
  position: absolute;
  cursor: pointer;
  font-size: 20px;
}

.imgRight {
  width: 27px;
  height: 38px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 40%;
  font-size: 20px;
}
.directionIcon:hover {
  opacity: 1;
}
#swiper {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.imgBox {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  /* margin-left: 2%; */
}
.imgDiv {
  width: 95%;
  margin-left: 15px;
}
.glory-pic {
  height: 70%;
  width: 95%;
  border-radius: 2px;
  margin-bottom: 5%;
  /* width: 260px;
        height: 120px; */
}
.glory-text {
  font-size: 15px;
  text-align: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /*数字代表文字需要显示几行*/
  word-break: break-all;
}
</style>