<template>
  <div class="homebacksd">
    <div class="service-detail">
      <div class="services-title">
        <span :title="serviceData.title">{{ serviceData.title }}</span>
      </div>
      <div class="service-content">
        <div class="service-intro" v-html="serviceData.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      serviceData: [],
    };
  },
  mounted() {
    this.getServiceData();
  },
  activated() {
    this.getServiceData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getServiceData() {
      await this.$axios
        .get("/subservices/GetSub", {
          params: {
            subid: this.$route.params.str,
          },
        })
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.serviceData = { ...response.data };
        });
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 800px) {
  .homebacksd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .service-detail {
    height: 50%;
    width: 80%;
    margin-top: 3%;
  }
  .services-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .service-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 10%;
  }
}
@media screen and (max-width: 700px) {
  .homebacksd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .service-detail {
    height: 50%;
    width: 80%;
    margin-top: 3%;
  }
  .services-title {
    font-size: 25px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .service-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 10%;
  }
}
</style>