<template>
  <div class="total">
    <div id="div1"><Header /></div>
    <div id="div2">
      <div id="right">
        <Main />
      </div>
    </div>
    <div id="div3"><Footer /></div>
  </div>
</template>

<script>
// 会导入./Header下面的index.vue组件
import Header from "./Header";
// import Navbar from "./Navbar";
import Main from "./main/Main";
import Footer from "./Footer";

export default {
  name: "Layout",
  components: { Header, Main, Footer },
};
// 导入子组件，缩写格式 AppHeader: AppHeader
</script>

<style scoped>
.total {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
#div1 {
  /* height: 80px; */
  height: 20%;
  width: 100%;
  flex: 1;
}
#div2 {
  /* flex: 1; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: row;
  height: 70%;
  width: 100%;
  flex: 0;
}
#div3 {
  height: 10%;
  width: 100%;
  flex: 1;
}
#right {
  flex: 1;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(151, 151, 151, 0.8)
    ),
    url("/assets/back.png") no-repeat;
  background-size: 100% 100%;
  /* height: 100%; */
  width: 100%;
}
</style>