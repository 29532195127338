<template>
  <div class="homebacknt">
    <div class="exec-pagent4">
      <div class="exec-dir">
        <div class="trends-title">
          <span>新闻动态</span>
        </div>
        <el-card>
          <el-table :data="newsData" style="width: 100%" class="box-table">
            <el-table-column prop="wtime" label="时间" min-width="20%">
            </el-table-column>
            <el-table-column prop="title" label="标题" min-width="80%">
              <template #default="scope">
                <div
                  :title="scope.row.title"
                  @click="openDetails(scope.row.newsid)"
                  style="
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                >
                  {{ scope.row.title }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
              style="margin-top: 30px; text-align: center"
            >
              <el-button
                style="
                  border: 1px solid;
                  border-color: #b4bccc;
                  padding: 0px;
                  margin-left: 6px;
                "
                size="mini"
                @click="handleCurrentChange"
                >GO</el-button
              >
            </el-pagination>
          </el-config-provider>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      newsData: [],
    };
  },
  mounted() {
    this.getNewsData();
  },
  activated() {
    this.getNewsData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getNewsData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getNewsData();
    },
    async getNewsData() {
      await this.$axios
        .get("/news/GetNewsAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.newsData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openDetails(id) {
      this.$router.push({
        name: "newsDetail",
        params: { str: id },
      });
    },
  },
};
</script>

<style lang="less">
.homebacknt {
  display: flex;
  // background-color: #f8f8f9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(173, 172, 172, 0.8)
    ),
    url("/assets/back.png") no-repeat;
  background-size: 100% 100%;
}

.exec-pagent4 {
  display: flex;
  // background-color: #f8f8f9;
  flex-direction: column;
  justify-content: center;
  // margin-left: -25%;
  // margin-left: 5%;
  width: 85%;
  margin-top: 3%;
}
.trends-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  padding-bottom: 15px;
  border-bottom: 1.5px solid rgb(110, 110, 110);
  width: 30%;
}
.el-card {
  width: 100%;
  margin-top: 5%;
  // margin-bottom: 10%;
}
</style>