<template>
  <div id="text">
    <h5>
      <div v-html="footerData.content" class="content"></div>
    </h5>
  </div>
</template>

<script>
import { GlobalMixin } from "@/mixins/globalMixin";
export default {
  name: "Footer",
  mixins: [GlobalMixin],
  data() {
    return {
      footerData: [],
    };
  },
  mounted() {
    this.getFooterData();
  },
  activated() {
    this.getFooterData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getFooterData() {
      await this.$axios
        .get("/footer/GetFooter", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.footerData = { ...response.data };
        });
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 800px) {
  #text {
    display: flex;
    justify-content: left;
    background: rgba(155, 154, 154, 0.8);
    position: initial;
    /* text-align: center; */
    justify-content: center;
  }
  .content {
    width: 100%;
    /* text-align: left; */
    /* margin-left: 65%; */
  }
}
@media screen and (max-width: 700px) {
  #text {
    display: flex;
    justify-content: left;
    background: rgba(155, 154, 154, 0.8);
    position: initial;
    text-align: center;
    font-size: 12px;
    display: none;
  }
  .content {
    width: 100%;
    text-align: left;
    display: block;
    /* margin-left: 65%; */
    /* font-size: 12px; */
  }
}
</style>