<template>
  <div class="homebackcp">
    <div class="comp-detail">
      <div class="comp-title">
        <span>公司概况</span>
      </div>
      <div class="comp-content">
        <div class="comp-intro" v-html="compData.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      compData: [],
    };
  },
  mounted() {
    this.getCompData();
  },
  activated() {
    this.getCompData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getCompData() {
      await this.$axios
        .get("/profiles/GetProfile", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.compData = { ...response.data };
        });
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 800px) {
  .homebackcp {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .comp-detail {
    // background-color: #f8f8f9;
    height: auto;
    width: 80%;
    margin-top: 3%;
  }
  .comp-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 20%;
  }
  .comp-content {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: justify;
    // margin-left: 10%;
  }
}
@media screen and (max-width: 700px) {
  .homebackcp {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .comp-detail {
    // background-color: #f8f8f9;
    height: auto;
    width: 80%;
    margin-top: 3%;
  }
  .comp-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 40%;
  }
  .comp-content {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: justify;
    // margin-left: 10%;
  }
}
</style>