// 涉外代理
<template>
  <div class="homebackfor">
    <div class="fore-inner">
      <div class="fore-title">
        <span>涉外代理</span>
      </div>
      <el-card>
        <div class="list-block">
          <div class="card-block">
            <div style="display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in tableData"
                :key="index"
                class="fore-card"
              >
                <div>
                  <span class="text-title" :title="item.title">{{
                    item.title
                  }}</span>
                  <div class="pic-contain">
                    <img class="agent-pics" :src="item.picurl" />
                  </div>
                  <span class="text-detail" :title="item.introduction">{{
                    item.introduction
                  }}</span>
                  <br />
                  <br />
                  <span class="fore-more" @click="openDetail(item.agentid)"
                    >>更多</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-config-provider :locale="locale">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            style="margin-top: 30px; text-align: center"
          >
            <el-button
              style="
                border: 1px solid;
                border-color: #b4bccc;
                padding: 0px;
                margin-left: 6px;
              "
              size="mini"
              @click="handleCurrentChange"
              >GO</el-button
            >
          </el-pagination>
        </el-config-provider>
      </el-card>
    </div>
  </div>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      foreId: "",
    };
  },
  //mounted是刷新的时候执行的
  mounted() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },

    async getTableData() {
      await this.$axios
        .get("/agents/GetAgentAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //打开涉外代理详情
    openDetail(id) {
      this.$router.push({
        name: "foreAgentDetail",
        params: { str: id },
      });
    },
  },
};
</script>

<style lang="less">
// pc端
@media screen and (min-width: 800px) {
  .fore-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 20%;
  }
  .fore-card {
    width: 330px;
    cursor: pointer;
    height: 480px;
    /* background-color: #fff; */
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-left: 6%;
    margin-bottom: 1%;
    position: relative;
    overflow: hidden;
  }
}
// 移动端
@media screen and (max-width: 700px) {
  .fore-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 30%;
  }
  .fore-card {
    width: 460px;
    cursor: pointer;
    height: 540px;
    /* background-color: #fff; */
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    // margin-left: 1%;
    margin-bottom: 1%;
    position: relative;
    overflow: hidden;
  }
}
.homebackfor {
  display: flex;
  // background-color: #f8f8f9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(173, 172, 172, 0.8)
    ),
    url("/assets/back.png") no-repeat;
  background-size: 100% 100%;
}

.list-block {
  width: 100%;
  /* height: 805px; */
  margin-left: 0.5%;
}
.card-block {
  overflow: auto;
  height: 95%;
}
.fore-inner {
  width: 95%;
  margin-top: 3%;
}

.el-card {
  width: 100%;
  margin-top: 5%;
  // margin-bottom: 10%;
}
.agent-pics {
  height: 240px;
  width: 100%;
}
.text-title {
  display: inline-block;
  width: 100%;
  height: 50px;
  color: #303133;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2%;
  margin-top: 6%;
}
.text-detail {
  width: 90%;
  color: #303133;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /*数字代表文字需要显示几行*/
  word-break: break-all;
  margin-top: 6%;
  text-align: justify;
  margin-left: 5%;
}
.pic-contain {
  width: 100%;
  /* height: 50%; */
  background-color: rgb(229, 245, 252);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fore-more {
  color: rgb(0, 115, 255);
  cursor: pointer;
  margin-left: 85%;
}
</style>