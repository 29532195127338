<template>
  <div class="homebacked">
    <div class="exec-pagent1">
      <div class="exec-dir1">
        <div class="exec-title">
          <span>执行董事</span>
        </div>
        <div class="exec-content">
          <div>
            <img
              class="exec-pic"
              :src="directorData.picurl"
              alt="执行董事照片"
            />
          </div>
          <div class="exec-right">
            <span class="exec-name">{{ directorData.director }}</span>
            <div class="exec-intro" v-html="directorData.introduction"></div>
          </div>
        </div>
      </div>
      <div class="pa-agent">
        <div class="pa-title">
          <span>专利代理人、专利工程师</span>
        </div>
        <div class="pa-content">
          <div class="pa-intro" v-html="stuffData.introduction"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      directorData: [],
      directorid: "",
      stuffData: [],
      stuffid: "",
    };
  },
  mounted() {
    this.getDirectorData();
    this.getStuffData();
  },
  activated() {
    this.getDirectorData();
    this.getStuffData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getDirectorData() {
      await this.$axios
        .get("/directors/GetDirector", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.directorData = { ...response.data };
          this.directorid = response.data.directorid;
        });
    },
    async getStuffData() {
      await this.$axios
        .get("/stuffs/GetStuff", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.stuffData = { ...response.data };
          this.stuffid = response.data.stuffid;
        });
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 800px) {
  .homebacked {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .exec-pagent1 {
    display: flex;
    // background-color: #f8f8f9;
    flex-direction: column;
    justify-content: center;
    // margin-left: -25%;
    width: 85%;
    margin-top: 3%;
  }
  .exec-dir1 {
    height: 50%;
    width: 100%;
    margin-left: 5%;
  }
  .exec-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 30%;
  }
  .exec-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 5%;
    // margin-left: 5%;
    width: 100%;
  }
  .exec-pic {
    width: 150px;
    height: 180px;
    // margin-top: 35%;
  }
  .exec-right {
    margin-left: 20px;
  }
  .exec-name {
    font-size: 25px;
  }
  .exec-intro {
    width: 90%;
    height: auto;
    text-align: justify;
    font-size: 18px;
  }

  .pa-agent {
    height: 50%;
    width: 100%;
    margin-left: 5%;
  }
  .pa-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 46%;
  }
  .pa-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 3%;
    // margin-left: 5%;
    width: 100%;
  }
  .pa-intro {
    width: 91%;
    height: auto;
    text-align: justify;
    font-size: 18px;
  }
}
@media screen and (max-width: 700px) {
  .homebacked {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }

  .exec-pagent1 {
    display: flex;
    // background-color: #f8f8f9;
    flex-direction: column;
    justify-content: center;
    // margin-left: -25%;
    width: 85%;
    margin-top: 3%;
  }
  .exec-dir1 {
    height: 50%;
    width: 100%;
    margin-left: 5%;
  }
  .exec-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 30%;
  }
  .exec-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 5%;
    // margin-left: 5%;
    width: 100%;
  }
  .exec-pic {
    width: 150px;
    height: 180px;
    margin-top: 35%;
  }
  .exec-right {
    margin-left: 20px;
  }
  .exec-name {
    font-size: 25px;
  }
  .exec-intro {
    width: 90%;
    height: 300px;
    text-align: justify;
    font-size: 18px;
  }

  .pa-agent {
    height: 50%;
    width: 100%;
    margin-left: 5%;
  }
  .pa-title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 80%;
  }
  .pa-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 3%;
    // margin-left: 5%;
    width: 100%;
  }
  .pa-intro {
    width: 91%;
    height: 300px;
    text-align: justify;
    font-size: 18px;
  }
}
</style>